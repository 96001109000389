<script setup>
import Clock from '@/../img/usps/clock.svg';
import Growth from '@/../img/usps/growth.svg';
import Handshake from '@/../img/usps/handshake.svg';
import Mobile from '@/../img/usps/mobile.svg';
import Money from '@/../img/usps/money.svg';
import { usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
const route = inject('route');

const locale = computed(() => usePage().props.locale);

const { t } = useI18n();

const usps = computed(() => [
    {
        icon: Clock,
        text: t('Decide where and when you work'),
        url: route(
            'blogs.show',
            locale.value === 'en' ? 'decide-where-and-when-you-work' : 'bepaal-zelf-waar-en-wanneer-je-werkt'
        ),
    },
    {
        icon: Mobile,
        text: t('Easily claim a shift via our app'),
        url: route(
            'blogs.show',
            locale.value === 'en' ? 'easily-claim-a-shift-via-our-app' : 'claim-eenvoudig-een-dienst-via-onze-app'
        ),
    },
    // { icon: MoneyBag, text: t('Make extra money while your friends are working!') },
    {
        icon: Handshake,
        text: t('Working as an employee or freelancer'),
        url: route(
            'blogs.show',
            locale.value === 'en' ? 'working-as-an-employee-or-freelancer' : 'in-loondienst-of-als-zzper-aan-de-slag'
        ),
    },
    {
        icon: Growth,
        text: t('Developing yourself and getting chosen faster'),
        url: route(
            'blogs.show',
            locale.value === 'en'
                ? 'developing-yourself-and-getting-chosen-faster'
                : 'jezelf-ontwikkelen-en-sneller-uitgekozen-worden'
        ),
    },
    {
        icon: Money,
        text: t('Get paid weekly'),
        url: route('blogs.show', locale.value === 'en' ? 'get-paid-weekly' : 'krijg-elke-week-uitbetaald'),
    },
]);
</script>
<template>
    <div class="relative py-10 text-white md:py-18 bg-blue">
        <div class="container">
            <div class="py-8 lg:py-16 xl:px-40">
                <h2 class="text-center uppercase text-lg lg:text-[36px] mb-8 md:mb-15 lg:leading-9">
                    <strong> {{ $t('Why') }}</strong> {{ $t('is working via Flex@ble') }}
                    <strong>{{ $t('so chill?') }} </strong>
                </h2>
                <div class="grid grid-cols-2 gap-8 mt-8 xl:grid-cols-3">
                    <template v-for="usp in usps" :key="usp.text">
                        <div class="">
                            <div class="flex items-center justify-center h-25 lg:h-35 xl:h-[200px] mb-4">
                                <img class="w-16 origin-bottom lg:w-20 xl:w-auto" :src="usp.icon" alt="" />
                            </div>

                            <div class="text-xs font-bold leading-4 uppercase lg:text-2xl">
                                {{ $t(usp.text) }}
                            </div>

                            <div class="pt-3">
                                <a
                                    v-if="usp.url"
                                    :href="usp.url"
                                    class="text-xs md:text-base font-bold leading-4 uppercase text-pink hover:text-opacity-75"
                                >
                                    {{ $t('Read more') }}
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
